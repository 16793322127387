<!--
 * @Description: 订单详情日志
 * @Author: 琢磨先生
 * @Date: 2023-01-18 11:33:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-18 11:38:21
-->
<template>
  <el-timeline>
    <el-timeline-item
      placement="top"
      v-for="(item, index) in logs"
      :key="index"
      :timestamp="`${item.create_at} ${item.type_text} ${(item.emp_name ?? '')}   ` "
    >
      <el-alert class="log" type="info" title :closable="false">
        <div class="tooltip">{{item.contentModel.tooltip}}</div>
        <div class="row order_base" v-if="item.contentModel.contact_name">
          <div class="col">
            <label>渠道：</label>
            <span>{{item.contentModel.ota_name}}</span>
          </div>
          <div class="col">
            <label>订单类型：</label>
            <span>{{item.contentModel.order_type}}</span>
          </div>
          <div class="col">
            <label>预订人：</label>
            <span>{{item.contentModel.contact_name}}</span>
          </div>
          <div class="col">
            <label>手机号：</label>
            <span>{{item.contentModel.mobile_phone}}</span>
          </div>
        </div>
        <div class="row">
          <div class="log_item" v-for="log in item.contentModel.items" :key="log.id">
            <label v-if="log.label">{{ log.label }}：</label>
            <span>{{ log.value }}</span>
          </div>
        </div>
        <div class="row" v-if="item.contentModel.contact_name">
          <div class="log_item">
            <label>备注：</label>
            <span>{{item.contentModel.remark?item.contentModel.remark:'--'}}</span>
          </div>
        </div>
      </el-alert>
    </el-timeline-item>
  </el-timeline>
</template>

<script>
export default {
  props: ["logs"],
};
</script>

<style   scoped>
/* 日志 */
.log .row {
  padding: 10px 0;
  border-bottom: 1px dashed var(--el-border-color);
}
.log .row:last-child {
  border-bottom: none;
}

.log .row.order_base {
  display: flex;
  flex-wrap: wrap;
}

.log .row.order_base .col {
  margin-right: 30px;
}

.log .row .log_item {
  margin-bottom: 5px;
}

.log .tooltip{
    color: #409EFF;
}
</style>