<!--
 * @Description: 生活费、费用支出
 * @Author: 琢磨先生
 * @Date: 2022-07-04 14:26:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:39:20
--> 
<template>
  <el-collapse class="consumption">
    <el-collapse-item :name="1" title>
      <template #title>
        <div class="flex-row flex-between" style="width: 100%">
          <b>生活费：¥{{ order.decimal_living_expense_amount }}</b>
        </div>
        <el-button type="primary" link @click.stop="onAdd" v-power="'seller/v1/expense/record/edit'">+添加生活费</el-button>
      </template>

      <el-table :data="order.living_expenses" border>
        <el-table-column label="项目" width="200">
          <template #default="scope">
            {{ scope.row.expense_item_name
            }}{{ scope.row.number > 0 ? "x" + scope.row.number : "" }}
          </template>
        </el-table-column>
        <el-table-column label="总额(元)" prop="decimal_total_amount" width="140"></el-table-column>
        <el-table-column label="日期" prop="pay_date" width="140"></el-table-column>
        <el-table-column label="实际成本(元)" width="140">
          <template #default="scope">
            <span v-if="scope.row.decimal_cost_amount > 0">￥{{ scope.row.decimal_cost_amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="业主占比(元)" width="140">
          <template #default="scope">￥{{ scope.row.decimal_landlord_amount }}</template>
        </el-table-column>
        <el-table-column prop label="商户占比(元)" width="140">
          <template #default="scope">￥{{ scope.row.decimal_business_amount }}</template>
        </el-table-column>
        <el-table-column prop label="商户实付(元)" width="140">
          <template #default="scope">￥{{ scope.row.decimal_business_cost_amount }}</template>
        </el-table-column>
        <el-table-column label="单价" width="120">
          <template #default="scope">
            <div
              v-if="scope.row.decimal_unit_price"
            >{{ scope.row.decimal_unit_price }}{{ scope.row.expense_item_unit }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" min-width="220"></el-table-column>
        <el-table-column prop="create_at" label="创建时间" width="180"></el-table-column>
        <el-table-column label="操作" fixed="right" width="80">
          <template #default="scope">
            <el-popconfirm
              title="确定要删除？"
              v-if="scope.row.boolDelete"
              @confirm="onDelete(scope.row)"
            >
              <template #reference>
                <el-button link size="small" class="text-danger" icon="Delete"></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-collapse-item>
  </el-collapse>

  <el-dialog
    title="新增费用支出"
    v-model="visibleDialog"
    width="800px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="费用项" prop="expense_item_id">
        <el-select v-model="form.expense_item_id" @change="changeExpense">
          <el-option v-for="item in expenseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房号" prop="house_store_id">
        <el-select v-model="form.house_store_id" placeholder>
          <el-option
            :label="item.house_no"
            :value="item.store_id"
            v-for="item in order.order_stores"
            :key="item.store_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数量">
        <el-col :span="5">
          <el-input v-model="form.number" placeholder @input="numberChange"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="单价(元)" prop="decimal_unit_price">
        <el-col :span="8">
          <el-input v-model="form.decimal_unit_price" placeholder @input="priceInput">
            <template #suffix>{{selection_expense?selection_expense.unit:''}}</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="费用总额(元)" prop="decimal_total_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_total_amount" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="业主占比" prop="decimal_ratio">
        <el-col :span="6">
          <el-input v-model="form.decimal_ratio" placeholder>
            <template #suffix>%</template>
          </el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="成本总额(元)" prop="decimal_cost_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_cost_amount" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="费用日期" prop="pay_date">
        <el-date-picker
          v-model="form.pay_date"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder
          :disabled-date="(date) => date > new Date()"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="备注">
        <el-col :span="16">
          <el-input v-model="form.remark" placeholder type="textarea"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="凭证图片">
        <upload-gallery @change="uploadChange"></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
// import common_api from "@/http/common_api";
import UploadGallery from "../../psd/upload_gallery.vue";
import expense_api from "@/http/expense_api";

export default {
  components: {
    UploadGallery,
  },
  data() {
    return {
      visibleDialog: false,
      //保存
      saving: false,
      form: {},
      rules: {
        decimal_total_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        expense_item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        house_store_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        pay_date: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //费用项目
      expenseList: [],
      //当前房源的占比
      storeExpense: null,
      //选择的费用项
      selection_expense: null,
    };
  },
  props: ["order", "enums"],
  emits: ["close"],
  watch: {
    order: {
      handler() {
        if (this.order && this.order.id) {
          this.form.house_store_id = this.order.order_stores[0].store_id;
          this.form.order_type = this.order.order_type;
        }
      },
      immediate: true,
    },
  },
  created() {
    // common_api.get_consumptions().then((res) => {
    //   if (res.code == 0) {
    //     this.conumptionItems = res.data;
    //   }
    // });
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.expenseList = res.data;
      }
    });
  },
  methods: {
    /**
     * 添加记录弹框打开
     */
    onAdd() {
      this.visibleDialog = true;
      this.form = {
        order_type: this.order.type,
        house_store_id: this.order.order_stores[0].store_id,
        number: 1,
      };
    },
    /**
     * 费用项选择更改
     */
    changeExpense() {
      this.selection_expense = this.expenseList.find(
        (o) => o.id == this.form.expense_item_id
      );
      this.loadGetRatio();
    },

    /**
     * 单价输入更改
     */
    priceInput() {
      if (!isNaN(this.form.number)) {
        var num = Number(this.form.number);
        if (
          this.form.decimal_unit_price &&
          !isNaN(this.form.decimal_unit_price)
        ) {
          this.form.decimal_total_amount =
            Math.floor(parseFloat(this.form.decimal_unit_price * num * 100)) /
            100;
        } else {
          this.form.decimal_total_amount = "";
        }
      }
    },

    /**
     * 数量更改
     */
    numberChange() {
      if (!isNaN(this.form.number)) {
        var num = Number(this.form.number);
        if (
          this.form.decimal_unit_price &&
          !isNaN(this.form.decimal_unit_price)
        ) {
          this.form.decimal_total_amount =
            Math.floor(parseFloat(this.form.decimal_unit_price * num * 100)) /
            100;
        }
      }
    },
    /**
     * 获取费用比例
     */
    loadGetRatio() {
      if (this.form.expense_item_id) {
        this.$http
          .post("seller/v1/expense/get_ratio", {
            expense_item_id: this.form.expense_item_id,
            store_id: this.form.house_store_id,
            order_type: this.form.order_type,
          })
          .then((res) => {
            if (res.code == 0) {
              this.form.decimal_ratio = res.data.decimal_ratio;
              this.form.decimal_unit_price = res.data.decimal_unit_price;
            }
          });
      } else {
        this.form.decimal_ratio = "0";
      }
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     */
    onSubmit() {
      this.form.order_id = this.order.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/expense/record/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.update = true;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http
        .get("seller/v1/expense/record/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.update = true;
            this.onClosed();
          }
        });
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close", this.update);
    },
  },
};
</script>

<style >
.el-collapse {
  border: none;
}
.el-collapse-item__header {
  border: none;
}
.el-collapse-item__wrap {
  border: none;
}
</style>