<template>
  <el-button type="primary" @click="onOpen" v-if="order.boolEditable" v-power="'seller/v1/houseOrder/update'">修改订单</el-button>
  <el-drawer title="修改订单" v-model="visibleDrawer" direction="rtl" size="1000px" :destroy-on-close="true" :modal="false"
    :append-to-body="true" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false">
      <el-divider direction="horizontal" content-position="left">
        <b>基本信息</b>
      </el-divider>
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder>
              <el-option :label="item.text" :value="item.value" v-for="item in enums.order_types"
                :key="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="渠道" prop="ota_id">
            <el-select v-model="form.ota_id" placeholder>
              <el-option :label="item.name" :value="item.id" :disabled="item.code == 'wxapplet'"
                v-for="item in ota_list" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form-item label="姓名" prop="contact_name">
            <el-input v-model="form.contact_name" placeholder></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="手机号" prop="mobile_phone">
            <el-input v-model="form.mobile_phone" placeholder></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider direction="horizontal" content-position="left">
        <b>房间信息</b>
      </el-divider>
      <edit-room :choose_room="form.order_stores" :group_stores="group_stores" @change="roomEditChange"
        @check-change="priceCheckChange"></edit-room>
      <el-divider direction="horizontal" content-position="left">备注信息</el-divider>
      <el-form-item label="操作备注">
        <el-input v-model="form.remark" type="textarea" rows="10" placeholder="备注信息"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="flex-row flex-between item-center">
        <div class="total">
          <div>
            <span class="text-16-bold">订单金额：</span>
            <span class="order_money">￥{{ totalAmount }}</span>
          </div>
          <div class="text-12 mt-8">
            <span>
              <span>已付：</span>
              <span class="text-orange">￥{{ order.decimal_receipt_amount }}</span>
            </span>
            <span class="ml-16">
              <span>还需收款：</span>
              <span class="text-red">￥{{ needPayAmount }}</span>
            </span>
          </div>
        </div>
        <div class="flex-row item-center">
          <div class="flex-row item-center" style="margin-right:10px;" v-if="checking">
            <el-icon><Loading /></el-icon>房费校验中...
          </div>
          <el-button type="primary" :loading="saving" @click="onSubmit" :disabled="checking">保存</el-button>
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import ota_api from "@/http/ota_api";
import EditRoom from "./edit_room.vue";
export default {
  components: {
    EditRoom,
  },
  data() {
    return {
      visibleDrawer: false,
      saving: false,
      //房费修改校验中...
      checking: false,
      form: {},
      rules: {
        contact_name: [{ required: true, message: "请输入", trigger: "blur" }],
        ota_id: [{ required: true, message: "请选择", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      //订单金额
      totalAmount: 0,
      needPayAmount: 0,
      //房间
      room_list: [],
      group_stores: [],
    };
  },
  props: ["order"],
  emits: ["success"],
  watch: {
    order: {
      handler() {
        if (this.order) {
          this.form = JSON.parse(JSON.stringify(this.order));
          this.form.remark = this.order.operate_remark;
          this.totalAmount = this.order.decimal_total_amount;
          this.needPayAmount = this.order.decimal_pay_amount;
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });
  },
  methods: {
    /**
     * 打开
     */
    onOpen() {
      this.visibleDrawer = true;
      // this.form.order_id = this.current_order.id;
    },

    /**
     * 房间更改回调
     */
    roomEditChange(list, amount) {
      this.form.accoms = list;
      var totalAmount =
        Math.floor(parseFloat(this.order.decimal_break_amount) * 100) +
        Math.floor(parseFloat(amount) * 100) +
        Math.floor(parseFloat(this.order.decimal_consumption_amount) * 100);
      this.totalAmount = totalAmount / 100;
      this.needPayAmount =
        (totalAmount -
          Math.floor(parseFloat(this.order.decimal_receipt_amount) * 100)) /
        100;
    },

    /**
     * 修改金额校验中
     */
    priceCheckChange(result) {
      if (result) {
        this.checking = true;
      } else {
        this.checking = false;
      }
    },

    /**
     *  提交下订单
     */
    onSubmit() {
      for (var i = 0; i < this.form.accoms.length; i++) {
        var item = this.form.accoms[i];
        if (!item.store_id) {
          this.$message.error("请选择房屋");
          return;
        }
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/houseOrder/update", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success", res.data);
                this.visibleDrawer = false;
              } else {
                this.$alert(res.msg, "提醒");
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.total .text-16-bold {
  font-size: 16px;
  font-weight: 700;
}

.total .text-12 {
  font-size: 12px;
}

.total .order_money {
  color: #053dc8;
  font-size: 16px;
  font-weight: 700;
}

.total .text-orange {
  color: #fa0;
}

.total .text-red {
  color: #f5222d;
}

.total .mt-8 {
  margin-top: 8px;
}

.total .ml-16 {
  margin-left: 16px;
}
</style>