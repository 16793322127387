<!--
 * @Description: 添加消费
 * @Author: 琢磨先生
 * @Date: 2022-07-03 10:54:27
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-18 21:30:45
-->
<template>
  <el-collapse class="consumption">
    <el-collapse-item :name="1" title>
      <template #title>
        <div class="flex-row flex-between" style="width: 100%">
          <b>其他消费：¥{{ order.consumption_total_amount }}</b>
        </div>
        <el-button type="primary" link @click.stop="onAddConsumption" v-power="'seller/v1/houseOrderConsumption/add'">+添加消费</el-button>
      </template>

      <el-table :data="order.consumptions" border>
        <el-table-column label="消费项目" width="200">
          <template #default="scope">{{ scope.row.item_name }} x{{ scope.row.number }}</template>
        </el-table-column>
        <el-table-column label="金额" width="140">
          <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
        </el-table-column>
        <el-table-column label="日期" prop="date" width="140"></el-table-column>
        <el-table-column label="备注" prop="remark" min-width="220"></el-table-column>
        <el-table-column label="操作" fixed="right" width="80">
          <template #default="scope">
            <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)">
              <template #reference>
                <el-button link size="small" class="text-danger" icon="Delete"></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-collapse-item>
    <!-- <el-collapse-item :name="2" title="">
      <template #title>
        <div class="flex-row flex-between" style="width: 100%">
          <span>收款金额：¥{{ order.receipts_total_amount }}</span>
          <add-receipt :order="order" @close="loadDtl"></add-receipt>
        </div>
      </template>
    </el-collapse-item>-->
  </el-collapse>

  <el-dialog
    title="添加消费"
    v-model="visibleDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
      <el-form-item label="消费项目" prop="item_id">
        <el-select v-model="form.item_id" @change="itemChange">
          <el-option
            v-for="item in conumptionItems"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房号" prop="store_id">
        <el-select v-model="form.store_id" placeholder>
          <el-option
            :label="item.house_no"
            :value="item.store_id"
            v-for="item in order.order_stores"
            :key="item.store_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数量" prop="number">
        <el-col :span="16">
          <el-input v-model="form.number" placeholder="请输入数量" @input="numberChange"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="金额" prop="decimal_amount">
        <el-col :span="16">
          <el-input v-model="form.decimal_amount" placeholder="请输入金额"></el-input>
        </el-col>
        <div class="help-block" v-if="this.selection_item && !this.selection_item.is_defined">
          单价：¥{{ this.selection_item.decimal_unit_price
          }}{{ this.selection_item.unit }}
        </div>
      </el-form-item>
      <el-form-item label="消费日期" prop="date">
        <el-date-picker
          v-model="form.date"
          value-format="YYYY-MM-DD"
          placeholder="选择日期时间"
          :disabled-date="(date) => date > new Date()"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-col :span="20">
          <el-input v-model="form.remark" type="textarea" placeholder="备注内容"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      visibleDialog: false,
      saving: false,
      //消费项列表
      conumptionItems: [],
      //选择的消费项
      selection_item: null,
      form: {},
      rules: {
        item_id: [{ required: true, message: "请输入", trigger: "blur" }],
        number: [{ required: true, message: "请输入", trigger: "blur" }],
        decimal_amount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        date: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        store_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["order", "enums"],
  emits: ["success"],
  created() {
    common_api.get_consumptions().then((res) => {
      if (res.code == 0) {
        this.conumptionItems = res.data;
      }
    });
  },
  methods: {
    /**
     * 添加消费记录
     */
    onAddConsumption() {
      this.visibleDialog = true;
      this.form = {
        number: 1,
        item: null,
        date: '',
        store_id: this.order.order_stores[0].store_id,
      };
    },
    /**
     * 消费项选择更改
     */
    itemChange() {
      var item = this.conumptionItems.find((o) => o.id == this.form.item_id);
      this.selection_item = item;
      this.numberChange();
    },
    /**
     * 计算总额（只对存在单价计算）
     */
    numberChange() {
      if (
        this.selection_item &&
        !this.selection_item.is_defined &&
        this.form.number
      ) {
        var num = parseInt(this.form.number, 0);
        this.form.decimal_amount =
          (num * parseFloat(this.selection_item.decimal_unit_price * 100)) /
          100;
      }
    },
    /**
     */
    onSubmit() {
      this.form.order_id = this.order.id;
      this.form.order_version = this.order.version;

      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/HouseOrderConsumption/add", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除消费记录
     */
    onDelete(item) {
      this.$http
        .post("seller/v1/HouseOrderConsumption/del", {
          id: item.id,
          order_version: this.order.version,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.$emit("success");
          }
          // this.onClosed();
        });
    },

    /**
     *
     */
    onClosed() {
      // this.$emit("close", this.update);
    },
  },
};
</script>

<style >
.el-collapse {
  border: none;
}
.el-collapse-item__header {
  border: none;
}
.el-collapse-item__wrap {
  border: none;
}
</style>