/*
 * @Description: 费用项
 * @Author: 琢磨先生
 * @Date: 2022-06-28 03:44:57
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-28 03:45:53
 */

import http from "./axios_init.js";

export default {
  /**
   * 获取所有的费用项
   * @returns
   */
  get_expenses() {
    return http.post("seller/v1/expense");
  },
};
