<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-07-11 14:01:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-09 10:39:40
-->
<template>
  <div class="order-amount">
    <div class="flex-row flex-between amount">
      <div>
        <div>订单金额</div>
        <div class="text-16-bold mt-5 blur">
          ¥{{ order.decimal_total_amount + order.decimal_deduct_score_amount }}
        </div>
        <div class="text-12-tip" v-if="order.decimal_deduct_score_amount > 0">
          含积分抵扣¥{{ order.decimal_deduct_score_amount }}
        </div>
      </div>
      <div>
        <div>平台费</div>
        <div class="text-16-bold mt-5 gray">
          ¥{{ order.decimal_mgr_fee_amount }}
        </div>
        <!-- <div class="text-12-tip" v-if="order.decimal_deduct_score_amount > 0">
          含积分抵扣¥{{ order.decimal_deduct_score_amount }}
        </div> -->
      </div>
      <div>
        <div>已付金额</div>
        <div class="text-16-bold mt-5 orange">
          ¥{{ order.decimal_receipt_amount }}
        </div>
        <div class="text-12-tip" v-if="order.decimal_deposit_amount > 0">
          含押金¥{{ order.decimal_deposit_amount }}
        </div>
      </div>
      <div>
        <div>还需付款</div>
        <div class="text-16-bold mt-5 red">¥{{ order.decimal_pay_amount - order.decimal_deduct_score_amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["order"],
};
</script>

<style  scoped>
.order-amount .amount {
  font-size: 14px;
  margin: 20px 0;
}
.order-amount .text-14-bold {
  font-weight: bold;
  font-size: 14px;
}
.order-amount .text-16-bold {
  font-size: 18px;
  font-weight: bold;
}
.order-amount .mt-5 {
  margin-top: 5px;
}
.order-amount .mt-8 {
  margin-top: 8px;
}
.order-amount .mt-16 {
  margin-top: 16px;
}
.order-amount .mb-16 {
  margin-bottom: 16px;
}
.order-amount .mt-30 {
  margin-top: 30px;
}
.order-amount .text-16-bold.blur {
  color: #053dc8;
}
.order-amount .text-16-bold.orange {
  color: #fa0;
}
.order-amount .text-16-bold.red {
  color: #f5222d;
}

.order-amount .text-16-bold.gray{
  color: #c76968;
}
.order-amount .text-12 {
  font-size: 12px;
}
.order-amount .text-12-tip {
  font-size: 12px;
  color: #999;
}
</style>