<template>
    <el-dialog title="收款" v-model="visibleQRCode" width="600px" :close-on-click-modal="false"
        :close-on-press-escape="false" :destroy-on-close="true" :append-to-body="true" @closed="onClosed">
        <div
            style="display:flex;flex-direction: column;align-items: center; justify-content: center; background-color: var(--el-color-info-light-9);padding:25px 0">
            <div style="font-size:18px;">收款金额：￥{{amount}}</div>
            <div style="margin-top:20px;">请联系客户尽快付款，超时后付款链接将关闭</div>
        </div>
        <div style="margin-top:20px;">
            复制图片/截图发送给客人，使用微信扫描二维码进行支付
        </div>
        <div class="qr_code">
            <el-image :src="qrCodeUrl"></el-image>
        </div>
    </el-dialog>
</template>

<script>
import QRCode from 'qrcode'

export default {
    data() {
        return {
            visibleQRCode: true,
            //二维码图片显示地址
            qrCodeUrl: '',
        }
    },
    props: ['code_url', 'amount'],
    created() {
        QRCode.toDataURL(this.code_url, {
            errorCorrectionLevel: 'H',
            width: 260,
            height: 260
        })
            .then(url => {
                this.qrCodeUrl = url;
            })
            .catch(err => {
                console.error(err)
            })
    },
}
</script>

<style  scoped>
.qr_code {
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>