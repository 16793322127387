<!--
 * @Description: 退房
 * @Author: 琢磨先生
 * @Date: 2022-07-12 02:21:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:32:13
-->


<template>
  <el-button type="primary" @click="onOpen" v-power="'seller/v1/houseOrder/checkout'">办理退房</el-button>
  <el-dialog
    title="办理退房"
    v-model="visibleDialog"
    width="800px"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- <el-alert type="warning" :closable="false" title=""
      >恢复预订后，违约金会自动删除</el-alert
    >-->
    <el-form :model="form" ref="form" :rules="rules">
      <!-- 订单金额显示 -->
      <el-table
        class="order_cancel_table"
        ref="table"
        :data="store_list"
        :show-header="false"
        row-key="id"
        @selection-change="selectionChange"
      >
        <el-table-column label type="selection" :reserve-selection="true"></el-table-column>
        <el-table-column label width="400">
          <template #default="scope">
            <div>{{ scope.row.house_name }}</div>
            <div>{{ scope.row.house_no }}</div>
          </template>
        </el-table-column>
        <el-table-column label>
          <template #default="scope">
            <div>
              {{ scope.row.checkin_date }} 至 {{ scope.row.checkout_date }}，{{
              scope.row.days
              }}晚
            </div>
            <div>
              房费：￥{{ scope.row.decimal_cost_amount }}，剩余房费:￥{{
              scope.row.decimal_residue_amount
              }}
            </div>
            <!-- <el-form-item label="违约金">
              <el-input
                v-model="scope.row.decimal_break_amount"
                placeholder
                @input="inputBreakAmount"
                :max="scope.row.decimal_residue_amount"
                clearable
                style="width: 140px"
              ></el-input>
            </el-form-item> -->
          </template>
        </el-table-column>
      </el-table>
      <el-form-item label style="margin-top: 20px">
        <el-input v-model="form.remark" type="textarea" rows="4" placeholder="请填写退房住备注信息"></el-input>
      </el-form-item>
    </el-form>
    <div style="margin-top: 20px">
      <div>
        <b>订单金额：￥{{ current_order.decimal_total_amount }}</b>
      </div>
      <div class="flex-row flex-between" style="margin-top: 10px">
        <b>
          已付金额：￥{{ current_order.decimal_receipt_amount }}
          <span>(含押金：￥{{ current_order.decimal_deposit_amount }})</span>
        </b>
        <b style="color: #f5222d">还需付款：￥{{ current_order.decimal_pay_amount }}</b>
      </div>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      visibleDialog: false,
      saving: false,
      current_order: {},
      form: {},
      rules: {},
      //
      selectionList: [],
      //可退房的房间
      store_list:[],
    };
  },
  props: ["order"],
  emits: ["close"],
  watch: {
    order: {
      handler() {
        this.current_order = Object.assign({}, this.order);
        this.totalAmount = this.current_order.total_amount;
        this.store_list = this.order.order_stores.filter((o) => o.boolCheckoutAvailable);
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 打开
     */
    onOpen() {
      this.visibleDialog = true;
      this.form.order_id = this.current_order.id;
      this.$nextTick(() => {
        this.$refs.table.toggleAllSelection();
      });
    },
    /**
     *
     */
    selectionChange(values) {
      this.selectionList = values;
      this.inputBreakAmount();
    },
    /**
     * 金额输入变动
     */
    inputBreakAmount() {
      if (this.selectionList.length <= 0) {
        this.current_order.decimal_total_amount =
          this.order.decimal_total_amount;
        this.current_order.decimal_pay_amount = this.order.decimal_pay_amount;
      } else {
        var breakAmount = 0;
        this.selectionList.forEach((item) => {
          console.log(item);
          if (!isNaN(parseFloat(item.decimal_break_amount))) {
            breakAmount += parseFloat(item.decimal_break_amount);
          }
          breakAmount += item.decimal_cost_amount;
        });
        //已住房费+违约金+消费
        this.current_order.decimal_total_amount =
          breakAmount + this.current_order.decimal_consumption_amount;
        this.current_order.decimal_pay_amount = (
          this.current_order.decimal_total_amount -
          this.current_order.decimal_receipt_amount
        ).toFixed(2);
      }
    },
    /**
     * 提交取消预定
     */
    onSubmit() {
      this.form.order_stores = [];
      this.selectionList.forEach((item) => {
        this.form.order_stores.push({
          id: item.id,
          decimal_break_amount: item.decimal_break_amount,
        });
      });
      //   if (this.form.order_stores.length <= 0) {
      //     this.$message.info("至少选择一条房间数据");
      //     return;
      //   }
      this.saving = true;
      this.$http
        .post("seller/v1/houseOrder/checkout", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.$emit("close");
            this.visibleDialog = false;
          }
          this.saving = false;
        });
    },
  },
};
</script>

<style>
.order_cancel_table.el-table td.el-table__cell,
.order_cancel_table.el-table th.el-table__cell.is-leaf {
  border: none;
}
</style>