<!--
 * @Description: 拆分订单
 * @Author: 琢磨先生
 * @Date: 2023-02-21 16:29:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:36:48
-->

<template>
  <div v-power="'seller/v1/houseOrder/split'">
    <el-dropdown-item v-if="order.boolDivisible" @click="onOpen">拆分订单</el-dropdown-item>
  </div>

  <el-dialog
    title="拆分订单"
    v-model="visibleDialog"
    width="800px"
    draggable
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert title="选择的房间将被拆分成独立订单！" type="warning" :closable="false" />
    <el-form :model="form" ref="form" :rules="rules">
      <!--  -->
      <el-table
        ref="table"
        :data="store_list"
        :show-header="false"
        @selection-change="selectionChange"
      >
        <el-table-column label type="selection"></el-table-column>
        <el-table-column label width="400">
          <template #default="scope">
            <div>{{ scope.row.huxing_name }}</div>
            <div>{{ scope.row.house_no }}</div>
          </template>
        </el-table-column>
        <el-table-column label>
          <template #default="scope">
            <div>
              {{ scope.row.checkin_date }} 至 {{ scope.row.checkout_date }}，{{
              scope.row.days
              }}晚
            </div>
            <div>房费：￥{{ scope.row.decimal_amount }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-form-item label style="margin-top: 20px">
        <el-input v-model="form.remark" type="textarea" rows="4" placeholder="请填写备注信息"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visibleDialog: false,
      saving: false,
      form: {},
      rules: {},
      store_list: [],
    };
  },
  props: ["order"],
  emits: ["close"],
  watch: {
    order: {
      handler() {
        // this.current_order = Object.assign({}, this.order);
        this.store_list = this.order.order_stores;
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 打开
     */
    onOpen() {
      this.visibleDialog = true;
      this.form.order_id = this.order.id;
      this.$nextTick(() => {
        this.$refs.table.toggleAllSelection();
      });
    },
    /**
     * 选择数据
     */
    selectionChange(values) {
      this.order_stores = values;
    },
    /**
     * 确定是否拆分
     */
    onSubmit() {
      this.form.order_stores = [];
      this.order_stores.forEach((item) => {
        this.form.order_stores.push({
          id: item.id,
        });
      });
      if (this.form.order_stores.length <= 0) {
        this.$message.info("至少选择一条房间数据");
        return;
      }
      this.saving = true;
      this.$http.post("seller/v1/houseOrder/split", this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$emit("close");
          this.visibleDialog = false;
        }
        this.saving = false;
      });
    },
  },
};
</script>

<style scoped>
</style>