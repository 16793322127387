<!--
 * @Description: 撤销入住
 * @Author: 琢磨先生
 * @Date: 2022-07-12 02:13:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-15 10:13:36
-->


<template>
  <div v-power="'seller/v1/houseOrder/undoCheckin'">
    <el-dropdown-item v-if="order.boolUndoAvailable" @click="onOpen">撤销入住</el-dropdown-item>
  </div>
  <el-dialog
    title="撤销入住"
    v-model="visibleDialog"
    width="800px"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert type="warning" :closable="false" title style="margin-bottom:20px;">撤销入住后，房间将回到已预订状态</el-alert>
    <el-form :model="form" ref="form" :rules="rules">
      <!-- 订单金额显示 -->
      <el-table
        class="order_cancel_table"
        ref="table"
        :data="current_order.order_stores.filter((o) => o.boolUndoAvailable)"
        :show-header="false"
        @selection-change="selectionChange"
      >
        <el-table-column label type="selection"></el-table-column>
        <el-table-column label width="400">
          <template #default="scope">
            <div>{{ scope.row.house_name }}</div>
            <div>{{ scope.row.house_no }}</div>
          </template>
        </el-table-column>
        <el-table-column label>
          <template #default="scope">
            <div>
              {{ scope.row.checkin_date }} 至 {{ scope.row.checkout_date }}，{{
              scope.row.days
              }}晚
            </div>
            <div>房费：￥{{ scope.row.amount }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-form-item label style="margin-top: 20px">
        <el-input v-model="form.remark" type="textarea" rows="4" placeholder="请填撤销入住备注信息"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      visibleDialog: false,
      saving: false,
      current_order: {},
      form: {},
      rules: {},
    };
  },
  props: ["order"],
  emits: ["close"],
  watch: {
    order: {
      handler() {
        this.current_order = Object.assign({}, this.order);
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 打开
     */
    onOpen() {
      this.visibleDialog = true;
      this.form.order_id = this.current_order.id;
      this.$nextTick(() => {
        this.$refs.table.toggleAllSelection();
      });
    },
    /**
     *
     */
    selectionChange(values) {
      this.order_stores = values;
    },

    /**
     * 提交取消预定
     */
    onSubmit() {
      this.form.order_stores = [];
      this.order_stores.forEach((item) => {
        this.form.order_stores.push({
          id: item.id,
        });
      });
      if (this.form.order_stores.length <= 0) {
        this.$message.info("至少选择一条房间数据");
        return;
      }
      this.saving = true;
      this.$http
        .post("seller/v1/houseOrder/undoCheckin", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.$emit("close");
            this.visibleDialog = false;
          }
          this.saving = false;
        });
    },
  },
};
</script>

<style>
.order_cancel_table.el-table td.el-table__cell,
.order_cancel_table.el-table th.el-table__cell.is-leaf {
  border: none;
}
</style>