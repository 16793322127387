/*
 * @Description: 渠道
 * @Author: 琢磨先生
 * @Date: 2022-08-28 03:49:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-28 03:53:14
 */
import http from "./axios_init";

export default {
  /**
   * 获取所有的渠道
   * @returns
   */
   get_otas() {
    return http.get("/seller/v1/ota");
  },
};
